import React, { Component } from 'react';
import DataController from '../Data/DataController'
import Blob from '../DataDisplays/BlobView'
import Monitor from '../DataDisplays/Sub_Monitor_Large'
import AlertMonitor from '../DataDisplays/Sub_Monitor_Small'
import BlobLegend from '../Utils/BlobLegend';
import BlobDebug from './BlobDebug';

class BlobController extends Component {
  _DataController = new DataController();
  state = {
    isLoaded: false,
    hasError: false,
    showLegend: false,
    dataSample: 0
  }
  constructor(props){
    super(props);
    this.blobResults = props.returnPoints;
    this.speed = props.speed;
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    this.loadData()
  }

  loadData = () =>{
    (async () => {
      this.newData = this._DataController.getApiData(this.returnedData);
    })();
  } 

  returnedData = (newData) => {
    if(newData.x.type === "good_x"){
      this.currentAnswer = "stable"
    }else{
      this.currentAnswer = "unstable"
    }
    
    this.currentData = this._DataController.getData();
    this.setState({
      isLoaded: true
    })
    this.timeCircle = document.getElementById("time-circle")
  }

  sampleChange = (s) =>{
    // try {
    //   if(this.timeCircle){
    //     this.timeCircle.style.strokeDashoffset = 3000 - (s * 30)
    //   }
    // } catch(e) {}
    this.setState({dataSample: s})   
  }

  approveCheck = (e) =>{
    clearTimeout(this.gameTimer);
    let win = false;
    let message = "Incorrect"
    let guess = "YOUR ANSWER: VULNERABLE"
    if(e.target.id === "yes-btn"){
      guess = "YOUR ANSWER: STABLE"
    }
    
    if(e.target.id === "yes-btn" && this.currentAnswer === "stable"){
      win = true;
      message = "Correct"
    }else if(e.target.id === "no-btn" && this.currentAnswer === "unstable"){
      win = true;
      message = "Correct"
    }else{
      win = false;
      message = "Incorrect"
    }
    this.blobResults(win, message, guess, this.currentData)
  }

  toggleLegend() {
    this.setState(prevState => ({
      showLegend: !this.state.showLegend
    }))
  }

  render(){
    if (this.state.isLoaded && !this.state.hasError) {
      return(
        <div id="blob-level">
          <Blob ref="mainBlob" sampleChange={this.sampleChange} currentData={this.currentData} speed={this.speed} />

          <button className={`toggle-legend button ${this.state.showLegend ? "active" : ""}`} onClick={this.toggleLegend.bind(this)}>Legend</button>
          {this.state.showLegend && <BlobLegend />}

          <div id="game-ui">  
            <div id="monitors-left">
              <Monitor label="OP"  speed={this.speed}  data={this.currentData.monitor1Data}/>
              <Monitor label="COD" speed={this.speed} data={this.currentData.monitor2Data}/>
              <Monitor label="TSS" speed={this.speed} data={this.currentData.monitor3Data}/>
            </div>
            <div id="center-ui">
              <div id="alert-monitors">
                <AlertMonitor label="OSTR" data={this.currentData.alert1[this.state.dataSample]} />
                <AlertMonitor label="ALUM" data={this.currentData.alert2[this.state.dataSample]} />
                <AlertMonitor label="CENT" data={this.currentData.alert3[this.state.dataSample]} />
                <AlertMonitor label="WAS" data={this.currentData.alert4[this.state.dataSample]}  />
              </div>

              <div id="buttons">
                <div id="yes">
                  <span>Stable</span>
                  <button id="yes-btn" onClick={this.approveCheck}></button>
                </div>
                <div id="no">
                  <span>Vulnerable</span>
                  <button id="no-btn" onClick={this.approveCheck}></button>
                </div>
              </div>
            </div>
            <div id="monitors-right">
              <Monitor  label="VFAs" speed={this.speed}  data={this.currentData.monitor4Data}/>
              <Monitor  label="NITROGEN" speed={this.speed}  data={this.currentData.monitor5Data}/>
              <Monitor  label="PRECIP %" speed={this.speed}  data={this.currentData.monitor6Data}/>
            </div>
          </div>

          {this.props.debugMode && <BlobDebug currentData={this.currentData} dataSample={this.state.dataSample} />}
        </div>
      )
    }
    if (!this.state.isLoaded && !this.state.hasError) {
      return (
        <div className="loading centered">
          <p>LOADING EPBR FORECASTING DATA</p>
        </div>
      )
    }
    if (!this.state.isLoaded && this.state.hasError) {
      return (
        <div className="error centered">
          <p>ERROR LOADING DATA</p>
        </div>
      )
    }
  }
}

export  default BlobController;