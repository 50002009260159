const ChartThreshold = {
  threshold: 0.3428,
  lineY: 0,

  drawThreshold(chartInstance) {
    const ctx = chartInstance.chart.ctx
    const xAxis = chartInstance.scales["x-axis-0"]
    const yAxis = chartInstance.scales["y-axis-0"]
    this.lineY = yAxis.height - (yAxis.height * this.threshold)

    ctx.beginPath()
    ctx.strokeStyle = "#D43C3C"
    ctx.lineWidth = 1

    ctx.moveTo(xAxis.left, this.lineY)
    ctx.lineTo(xAxis.right, this.lineY)
    ctx.stroke()
  },

  beforeDatasetsDraw: function(chart) {
    this.drawThreshold(chart)
  },
}

const ChartVerticalLine = {
  threshold: 0.666,
  lineX: 0,

  drawVerticalLine(chartInstance) {
    const ctx = chartInstance.chart.ctx
    const xAxis = chartInstance.scales["x-axis-0"]
    const yAxis = chartInstance.scales["y-axis-0"]
    this.lineX = xAxis.width * this.threshold

    ctx.save()
    ctx.beginPath()
    ctx.strokeStyle = "#707070"
    ctx.setLineDash([5, 5])
    ctx.lineWidth = 1

    ctx.moveTo(this.lineX, yAxis.top)
    ctx.lineTo(this.lineX, yAxis.bottom)
    ctx.stroke()
    ctx.restore()

    // Background box
    ctx.save()
    ctx.fillStyle = "#151515"
    ctx.fillRect(this.lineX, yAxis.top, (chartInstance.width - this.lineX), (chartInstance.height - 27))
    ctx.restore()
  },

  beforeDraw: function(chart) {
    this.drawVerticalLine(chart)
  }
}

const ChartText = {
  drawText(chartInstance) {
    const ctx = chartInstance.chart.ctx
    const xAxis = chartInstance.scales["x-axis-0"]
    const yAxis = chartInstance.scales["y-axis-0"]
    const verticalX = ChartVerticalLine.lineX
    const upcomingTextX = chartInstance.width - ((chartInstance.width - verticalX) / 2)
    const simulatedTextX = chartInstance.width - verticalX
    const thresholdY = ChartThreshold.lineY
    const upsetTextY = chartInstance.height - (chartInstance.height - thresholdY)

    ctx.fillStyle = "#ffffff"
    ctx.font = "12px InputMonoCompressed Light, monospace"
    ctx.textAlign = "center"

    ctx.save()
    ctx.translate(upcomingTextX, 27)
    ctx.fillText("UPCOMING", 0, 0)
    ctx.restore()

    ctx.save()
    ctx.translate(simulatedTextX, 27)
    ctx.fillText("SIMULATED", 0, 0)
    ctx.restore()

    ctx.save()
    ctx.textAlign = "center"
    ctx.translate(15, upsetTextY)
    ctx.rotate((270 * Math.PI) / 180)
    ctx.fillText("UPSET THRESHOLD", 0, 0)
    ctx.restore()

    // Outer border
    ctx.save()
    ctx.fillStyle = "rgba(0,0,0,0)"
    ctx.strokeStyle = "#ffffff"
    ctx.lineWidth = 2
    ctx.strokeRect(xAxis.left, yAxis.top, (chartInstance.width - 27), (chartInstance.height - 27))
    ctx.restore()

    if (chartInstance.config.startDate) {
      ctx.save()
      ctx.textAlign = "left"
      ctx.translate(27, (yAxis.bottom + 22))
      ctx.fillText(chartInstance.config.startDate.toLocaleDateString("en-US", {
        timeZone: "UTC",
        dateStyle: "short"
      }), 0, 0)
      ctx.restore()
    }

    if (chartInstance.config.endDate) {
      ctx.save()
      ctx.textAlign = "right"
      ctx.translate(xAxis.right, (yAxis.bottom + 22))
      ctx.fillText(chartInstance.config.endDate.toLocaleDateString("en-US", {
        timeZone: "UTC",
        dateStyle: "short"
      }), 0, 0)
      ctx.restore()
    }
  },

  afterDatasetsDraw: function(chart) {
    this.drawText(chart)
  }
}

export { ChartThreshold, ChartVerticalLine, ChartText }