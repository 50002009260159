import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import HowToPage from './HowToPage';
import AboutPage from './AboutPage';
import MuteButton from '../Utils/MuteButton';

class GameHeader extends Component {
  state = {showMenu: false, showHowToPlay: false, showAbout: false}
  constructor(props){
    super(props);
    this.maxLives = this.props.maxLives
    this.lives = this.currentLives;
    // this.stase
    this.livesElems=[]
  }

  lifeDots = () =>{
    this.livesElems=[]
    for (let i = 0; i < this.maxLives; i++) {
      let lifeDot
      if(i >= this.props.currentLives){
        lifeDot =  React.createElement('div', {className:'dead', key: i+"_life"} );
      }else{
        lifeDot =  React.createElement('div', {key: i+"_life"});
      }
      this.livesElems.push(lifeDot)
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      showMenu: !this.state.showMenu
    }))
  }

  toggleHowToPlay() {
    this.setState(prevState => ({
      showHowToPlay: !this.state.showHowToPlay,
      showMenu: false,
    }))
  }

  toggleAbout() {
    this.setState(prevState => ({
      showAbout: !this.state.showAbout,
      showMenu: false,
    }))
  }

  confirmQuit() {
    if (window.confirm("Are you sure? Your progress and score will be lost.")) {
      this.props.history.push("/")
    } else {
      this.toggleMenu()
      return false
    }
  }

  render() {
    this.lifeDots()
    let app = document.getElementsByTagName("BODY")[0];
    if (this.state.showHowToPlay) {
      app.style.height = "100vh"
      app.style.overflow = "hidden"
      return (
        <div className="full-modal">
          <HowToPage close={this.toggleHowToPlay.bind(this)} />
        </div>
      )
    } else if (this.state.showAbout) {
      app.style.height = "100vh"
      app.style.overflow = "hidden"
      return (
        <div className="full-modal">
          <AboutPage close={this.toggleAbout.bind(this)} />
        </div>
      )
    } else {
      app.style.height = "auto"
      app.style.overflow = "auto"
      return (
        <div className="game-header">
          <div className="game-info">
            <div className="menu-btn">
              <button className="primary button" onClick={this.toggleMenu.bind(this)}>Menu</button>
              {this.state.showMenu &&
                <div className="menu">
                  <button className="button primary" onClick={this.toggleHowToPlay.bind(this)}>How to play</button>
                  <button className="button primary" onClick={this.toggleAbout.bind(this)}>About</button>
                  <button className="button primary" onClick={this.confirmQuit.bind(this)}>Quit</button>
                </div>
              }

              <MuteButton />
            </div>
            <div className="level-number">Stage <span >{this.props.level}</span></div>
            <div className="score-lives">
              <div className="score">Score: <span>{this.props.score}</span></div>
              <div className="lives">{this.livesElems}</div>
            </div>
          </div>
        </div>
      )
    }
  }
}
export default withRouter(GameHeader)