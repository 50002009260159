import React, { Component } from 'react';
import * as THREE from "three";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import HomeView from './Views/HomePage';
import GameView from './Views/GameController';
import AboutPage from './Views/AboutPage';
import HowToPage from './Views/HowToPage';
import {Howl} from 'howler';

import './styles/App.scss';

class App extends Component {
  constructor(props) {
    super(props)
    window.BLOB_SPHERE_GEOMETRY = new THREE.SphereGeometry(0.75, 100, 100);
    window.BLOB_EDGES_GEOMETRY = new THREE.EdgesGeometry(window.BLOB_SPHERE_GEOMETRY)

    this.setupMusic()
  }

  setupMusic() {
    window.APP_SOUNDS = {
      background: new Howl({ src: "./sounds/background.mp3", loop: true }),
      hitSuccess: new Howl({ src: "./sounds/hit-success.mp3" }),
      hitFailure: new Howl({ src: "./sounds/hit-failure.mp3" }),
      bonusSuccess: new Howl({ src: "./sounds/bonus-success.mp3" }),
      bonusFailure: new Howl({ src: "./sounds/bonus-failure.mp3" }),
      gameOver: new Howl({ src: "./sounds/game-over.mp3" }),
    }

    if (process.env.NODE_ENV !== "production") {
      window.Howler.mute(true)
    }

    window.Howler.volume(0.5)
    window.APP_SOUNDS.background.play()
    window.APP_SOUNDS.background.fade(0, 1, 3000)
  }

  render() {
    return (
      <Router>
        <div className="App">
          <div className="background-video">
            <video src="/videos/glitch1.mp4" autoPlay loop muted playsInline />
          </div>

          <Switch>
            <Route exact path="/">
              <HomeView />
            </Route>

            <Route path="/game">
              <GameView />
            </Route>

            <Route path="/about">
              <AboutPage />
            </Route>

            <Route path="/how-to-play">
              <HowToPage />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
