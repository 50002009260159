import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import FPS from '../Utils/Stats.js'
import BonusLevelDisplay from '../Views/BonusLevel'
import MultipleChoice from '../Views/MultipleChoice'
import BlobLevel  from "../DataDisplays/BlobController";
import EndLevelView from '../Views/EndLevelView'
import GameHeader from '../Views/GameHeader'
import GameOver from '../Views/GameOver'

class GameController extends Component {
  state = {
    levelType: "blob",
    levelContent: null,
  }

  constructor(props) {
    super(props)
    
    this.speed = 15
    this.currentLevel = 1
    this.lives = (props.location.state && props.location.state.lives) ? props.location.state.lives : 3
    this.maxLives = (props.location.state && props.location.state.maxLives) ? props.location.state.maxLives : 3
    this.score = 0
    this.blobPoints = 300
    this.multiPoints = 100
    this.totalBlobLevels = []
    this.totalMultiLevels = []
    this.totalBonusLevels = []
    this.totalBlobPoints = 0
    this.totalMultiPoints = 0
    this.totalBonusPoints = 0
    this.currentMultiAnswer = 0
    this.currentLevelOrder = 0

    this.levelOrder = (props.location.state && props.location.state.levelOrder) ? props.location.state.levelOrder : [
      "blob",
      "multi",
      "blob",
      "bonus",
      "multi",
      "blob",
      "multi",
      "bonus",
    ]
    this.debugMode = this.checkForDebugMode() ? true : false
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "production" && !this.debugMode) {
      window.addEventListener("beforeunload", this.unloadConfirm)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.unloadConfirm)
  }

  checkForDebugMode() {
    return (this.props.location.state && this.props.location.state.debugMode) ||
      (this.props.location.search && this.props.location.search.includes("debug"))
  }

  unloadConfirm(e) {
    const msg = "Are you sure? Your progress and score will be lost."
    e.preventDefault()
    e.returnValue = msg
    return msg
  }

  levelResults = (win, points) =>{
    if(win){
      this.newPoints = points
      this.score += this.newPoints
    }else{
      this.newPoints = 0
      this.lives--
    }
  }

  blobResults = (win, message, guess, currentData) =>{
    this.levelResults(win, this.blobPoints)
    this.levelContent = <EndLevelView
      type="blob"
      newLevel={this.newLevel} 
      win={win}
      lives = {{currentLives: this.lives, maxLives: this.maxLives}}
      points = {{totalPoints: this.score, newPoints:this.newPoints}}
      guess = {guess}
      currentData={currentData}
      debugMode={this.debugMode}
    />
    this.totalBlobPoints += this.newPoints
    this.totalBlobLevels.push(win)
    this.setState({levelType: "end"})
  }
  multiResults = (answerIndex, question, guess, correctAnswer) =>{
    let win = false;
    if(Number(answerIndex) === 0)win = true;
    this.levelResults(win, this.multiPoints)
    this.levelContent = <EndLevelView 
      newLevel={this.newLevel} 
      win={win}
      lives = {{currentLives: this.lives, maxLives: this.maxLives}}
      points = {{totalPoints: this.score, newPoints:this.newPoints}}
      answerIndex={answerIndex}
      question={question}
      livesTotal={this.lives} />
    this.totalMultiPoints += this.newPoints
    this.totalMultiLevels.push(win)
    this.setState({levelType: "end"})
  }
  bonusResults = (bonus_points) =>{
    this.score += bonus_points;
    // this.totalBonusLevels.push({points:bonus_points});
    this.totalBonusPoints += bonus_points
    this.totalBonusLevels.push(bonus_points)
    this.newLevel()
  }
  newLevel = () =>{
    if(this.lives === 0)this.setState({gameOver: true})
    this.currentLevel++;
    this.currentLevelOrder++;
    if(this.currentLevelOrder > this.levelOrder.length - 1) this.currentLevelOrder = 0;
    this.setState({levelType: this.levelOrder[this.currentLevelOrder]})
  }

  levelSwitch = () =>{
    switch(this.state.levelType) {
      case 'blob':
        this.levelContent = <BlobLevel ref="blobLevel" returnPoints={this.blobResults} speed={this.speed} debugMode={this.debugMode} />
        return this.levelContent
      case 'multi':
        this.levelContent = <MultipleChoice ref="multipleChoiceView" endMultiLevel={this.multiResults} answer={0} debugMode={this.debugMode}></MultipleChoice>
        return this.levelContent
      case 'bonus':
        this.levelContent = <BonusLevelDisplay ref="bonusLevel" score={this.score} closeBonus={this.bonusResults} />
        return this.levelContent
      default:
        return this.levelContent
    }
  }

  render() {
    if (this.state.gameOver === true) {
      return(
        <GameOver totalPoints={this.score} 
          blobData={{points: this.totalBlobPoints, stages:this.totalBlobLevels }}
          multiData={{points: this.totalMultiPoints, stages:this.totalMultiLevels }}
          bonusData={{points: this.totalBonusPoints, stages:this.totalBonusLevels }} />
      )
      // return <Redirect to="/game-over" />
    }else{
      return (
        <div className="game-holder">
          {/* <FPS /> */}
          <GameHeader maxLives={this.maxLives} currentLives={this.lives} level={this.currentLevel} score={this.score}/>
          {this.levelSwitch()}
        </div>
      )
    }
  }
}
export default withRouter(GameController)