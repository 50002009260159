const SoundHit = {
  play(sound) {
    try {
      sound.play()
      sound.fade(0, 1, 250)
    } catch(e) {
      console.error("error playing hit", e)
    }
  }
}

export default SoundHit