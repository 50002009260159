import React from 'react';

class BlobDebug extends React.Component {
  constructor(props) {
    super(props)
    this.startDate = new Date(props.currentData.timestampData.x[0] * 1000)
    this.endDate = new Date(props.currentData.timestampData.y[props.currentData.timestampData.y.length - 1] * 1000)
  }

  render() {
    return (
      <div className="blob-debug">
        <div className="row">
          <p>Batch</p>
          <p>{this.props.currentData.batch}</p>
        </div>
        
        <div className="row">
          <p>Stable</p>
          <p>{this.props.currentData.stable.toString()}</p>
        </div>

        {(typeof this.props.dataSample !== "undefined") && (
          <div className="row">
            <p>Sample</p>
            <p>{this.props.dataSample}</p>
          </div>
        )}

        <div className="row">
          <p>Start Date</p>
          <p>{this.startDate.toLocaleDateString("en-US", {
            timeZone: "UTC",
            dateStyle: "short"
          })}</p>
        </div>
        <div className="row">
          <p>End Date</p>
          <p>{this.endDate.toLocaleDateString("en-US", {
            timeZone: "UTC",
            dateStyle: "short"
          })}</p>
        </div>
      </div>
    )
  }
}

export default BlobDebug