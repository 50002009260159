import React from 'react';

class BlobLegend extends React.Component {
  render() {
    return (
      <div className="blob-legend">
        <div className="row">
          <div className="title">OP</div>
          <div className="description">Primary Effluent OP to AB1</div>
        </div>
        <div className="row">
          <div className="title">COD</div>
          <div className="description">Primary Effluent Chemical Oxidative Demand to AB1</div>
        </div>
        <div className="row">
          <div className="title">TSS</div>
          <div className="description">Primary Effluent Total Suspended Solids to AB1</div>
        </div>
        <div className="row">
          <div className="title">VFA</div>
          <div className="description">Total Volatile Fatty Acids to AB1</div>
        </div>
        <div className="row">
          <div className="title">Nitrogen</div>
          <div className="description">Primary Effluent Total Nitrogen to AB1</div>
        </div>
        <div className="row">
          <div className="title">Precip %</div>
          <div className="description">Levels of Precipitation</div>
        </div>
        <div className="row spacer">
          <div className="title">OSTR</div>
          <div className="description">Volume of OSTR return</div>
        </div>
        <div className="row">
          <div className="title">ALUM</div>
          <div className="description">Alum Dosing</div>
        </div>
        <div className="row">
          <div className="title">CENT</div>
          <div className="description">Level of CENT Tank</div>
        </div>
        <div className="row">
          <div className="title">WAS</div>
          <div className="description">Level of WAS Tank</div>
        </div>

        {/* Need to fix z-index issues before implementing */}
        {/* <button className="button" onClick={this.toggleLegend.bind(this)}>Close</button> */}
      </div>
    )
  }
}

export default BlobLegend