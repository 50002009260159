import React from "react";

const FormulaImage = ({ path, ...rest }) => {
  const ImportedIconRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../${path}.svg`)).default;
      } catch (err) {
        console.error(err)
        throw err;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [path]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return <ImportedIcon {...rest} />;
  }

  return null;
};

export default FormulaImage