// import React from 'react';
import BadData from '../Data/ex_bad_x_data';
import BadDataY from '../Data/ex_bad_y_data';
import GoodData from '../Data/ex_good_x_data';
import GoodDataY from '../Data/ex_good_y_data';

class DataController{
  constructor(){
    this.apiUrl = process.env.NODE_ENV === "production" ? "/api/random" : "https://training-platform-api-ul7zlo7phq-uw.a.run.app/random"
    // this.newData = this.newData.bind(this);
    this.getData = this.getData.bind(this);
    this.goodData = this.goodData.bind(this);
    this.badData = this.badData.bind(this);
    this.randomData = this.randomData.bind(this);
    this.DataSets = [BadData, GoodData]
    
    this.batch = null
    this.stable = null
    this.blobRadius = []
    this.blobNoise = []
    this.blobNoiseHeight = []
    this.backgroundColor = []
    this.monitor1Data = []
    this.monitor2Data = []
    this.monitor3Data = []
    this.monitor4Data = []
    this.monitor5Data = []
    this.monitor6Data = []
    this.alert1 = []
    this.alert2 = []
    this.alert3 = []
    this.alert4 = []

    this.timestampData = {}
    this.nsData = {}
    this.data = {}
    // ['o5','o6','max_o6','o3','precipProbability','1021','1023','1029','1016','1026','4938','75','o14','o12']

    BadData.data.map((d, i) => {
      // console.log(i)
      this.blobRadius.push(d[0])
      this.blobNoise.push(d[1])
      this.blobNoiseHeight.push(d[2])
      this.backgroundColor.push(d[3])

      this.monitor1Data.push(d[5])
      this.monitor2Data.push(d[6])
      this.monitor3Data.push(d[7])

      this.monitor4Data.push(d[8])
      this.monitor5Data.push(d[9])
      this.monitor6Data.push(d[4])
      this.alert1.push(d[10])
      this.alert2.push(d[11])
      this.alert3.push(d[12])
      this.alert4.push(d[13])
      return ""
    });
    
  }
  async getApiData(callBack){
    this.resetData()
    const response = await fetch(this.apiUrl)
    const json = await response.json()
    this.batch = json.x.batch
    this.stable = json.x.type.includes("good") ? true : false
    this.nsData = {
      x: json.x.ns_data,
      y: json.y.ns_data,
    }
    this.timestampData = {
      x: json.x.timestamps_data.flat(),
      y: json.y.timestamps_data,
    }
    this.data = {
      x: json.x.data,
      y: json.y.data,
    }
    for (const d of json.x.data) {
      this.blobRadius.push(d[0])
      this.blobNoise.push(d[1])
      this.blobNoiseHeight.push(d[2])
      this.backgroundColor.push(d[3])

      this.monitor1Data.push(d[5])
      this.monitor2Data.push(d[6])
      this.monitor3Data.push(d[7])
      this.monitor4Data.push(d[8])
      this.monitor5Data.push(d[9])
      this.monitor6Data.push(d[4])

      this.alert1.push(d[10])
      this.alert2.push(d[11])
      this.alert3.push(d[12])
      this.alert4.push(d[13])
    }
    callBack(json)
    // this.resetData();
  }
  getData = () =>{
    return({
      batch: this.batch,
      stable: this.stable,
      blobRadius:this.blobRadius,
      blobNoise: this.blobNoise,
      blobNoiseHeight:this.blobNoiseHeight,
      backgroundColor:this.backgroundColor,
      monitor1Data: this.monitor1Data,
      monitor2Data: this.monitor2Data,
      monitor3Data: this.monitor3Data,
      monitor4Data: this.monitor4Data,
      monitor5Data: this.monitor5Data,
      monitor6Data: this.monitor6Data,
      alert1:this.alert1,
      alert2:this.alert2,
      alert3:this.alert3,
      alert4:this.alert4,
      data:this.data,
      timestampData:this.timestampData,
      nsData:this.nsData,
      approved:false
    })
  }
  // console.log(monitor1Data)
  // blob radius = o5
  // blob noise = o6
  // bob noise height max_o6
  // background color o3
  // monitor 1 OP = 1021
  // monitor 2 COD  = 1023
  // monitor 3 TSS 1029
  // monitor 4 VFA 1016
  // monitor 5 Nitrogen 1026
  randomData = () =>{
    this.resetData();
    let rNum = Math.round(Math.random());
    if(rNum === 0){
      this.badData()
    }else{
      this.goodData()
    }
  }
  resetData = () =>{
    this.blobRadius = []
    this.blobNoise = []
    this.blobNoiseHeight = []
    this.backgroundColor = []
    this.monitor1Data = []
    this.monitor2Data = []
    this.monitor3Data = []
    this.monitor4Data = []
    this.monitor5Data = []
    this.monitor6Data = []
    this.alert1 = []
    this.alert2 = []
    this.alert3 = []
    this.alert4 = []
  }

  badData = () =>{
    this.resetData();
    BadData.data.map((d, i) => {
      // console.log(i)
      this.blobRadius.push(d[0])
      this.blobNoise.push(d[1])
      this.blobNoiseHeight.push(d[2])
      this.backgroundColor.push(d[3])

      this.monitor1Data.push(d[5])
      this.monitor2Data.push(d[6])
      this.monitor3Data.push(d[7])
      this.monitor4Data.push(d[8])
      this.monitor5Data.push(d[9])
      this.monitor6Data.push(d[4])
      return ""
    });
    return({blobRadius:this.blobRadius,
      blobNoise: this.blobNoise,
      blobNoiseHeight:this.blobNoiseHeight,
      backgroundColor:this.backgroundColor,
      monitor1Data: this.monitor1Data,
      monitor2Data: this.monitor2Data,
      monitor3Data: this.monitor3Data,
      monitor4Data: this.monitor4Data,
      monitor5Data: this.monitor5Data,
      monitor6Data: this.monitor6Data,
      alert1:this.alert1,
      alert2:this.alert2,
      alert3:this.alert3,
      alert4:this.alert4,
      yData:BadDataY.data,
      approved:false
    })
  }

  goodData = () =>{
    this.resetData();
    GoodData.data.map((d, i) => {
      // console.log(i)
      this.blobRadius.push(d[0])
      this.blobNoise.push(d[1])
      this.blobNoiseHeight.push(d[2])
      this.backgroundColor.push(d[3])

      this.monitor1Data.push(d[5])
      this.monitor2Data.push(d[6])
      this.monitor3Data.push(d[7])
      this.monitor4Data.push(d[8])
      this.monitor5Data.push(d[9])
      this.monitor6Data.push(d[4])

      this.alert1.push(d[10])
      this.alert2.push(d[11])
      this.alert3.push(d[12])
      this.alert4.push(d[13])
      return ""
    });
    return({blobRadius:this.blobRadius,
      blobNoise: this.blobNoise,
      blobNoiseHeight:this.blobNoiseHeight,
      backgroundColor:this.backgroundColor,
      monitor1Data: this.monitor1Data,
      monitor2Data: this.monitor2Data,
      monitor3Data: this.monitor3Data,
      monitor4Data: this.monitor4Data,
      monitor5Data: this.monitor5Data,
      monitor6Data: this.monitor6Data,
      alert1:this.alert1,
      alert2:this.alert2,
      alert3:this.alert3,
      alert4:this.alert4,
      yData:GoodDataY.data,
      approved:true
    })
  }
}
export default DataController;