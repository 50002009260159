import React, { Component } from "react"
import FormulaImage from "../Components/FormulaImage"

class MultipleChoiceEnd extends Component {
  componentDidMount() {}

  render() {
    return (
      <div ref="multiAnswers" id="multi-guess">
        <div id="end-qa">
          <div className="end-qa-holder">
            <span>Question</span>
            <div
              ref="endQ"
              id="end-q"
              dangerouslySetInnerHTML={{ __html: this.props.question.title }}
            ></div>
          </div>

          <div id="qa-row" ref="qaRow" className={`${!this.props.win ? "incorrect" : ""}`}>
            <div className="end-a-holder">
              <span>Your Answer</span>
              <div ref="endA" id="end-a">
                <FormulaImage
                  path={`images/formulas/${this.props.question.id}/${this.props.answerIndex}`}
                />
              </div>
            </div>

            {!this.props.win && (
              <div className="end-a-holder" id="multi-ca">
                <span>Correct Answer</span>
                <div ref="endCa" id="end-ca">
                  <FormulaImage
                    path={`images/formulas/${this.props.question.id}/0`}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default MultipleChoiceEnd
