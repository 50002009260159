import React, { Component } from 'react';
import SoundHit from '../Utils/SoundHit'

class BonusLevel extends Component {
  constructor(props){
    super(props)
    this.currentPoints = props.totalPoints
    this.closeBonus = props.closeBonus;
    this.bonusPoints = 0;
    this.playerX = 0;
    this.playerY = 0;
    this.playerRadius = 6;
    this.playerAngle = 0;
    this.pathRadius = 250;
    this.centerX = 300
    this.centerY = 300
    this.isPlaying =  false;
    this.currentScore = this.props.score
    this.pointBlobs = [
      {"radius":18, "angle":230, "points":50},
      {"radius":50, "angle":0, "points":10},
      {"radius":25, "angle":130, "points":25}
    ]
  }

  startBonusRound = (score) =>{
    // this.currentScore = score
    this.bonusPoints = 0;
    this.isPlaying = true;
    this.refs.pointsDisplay.style.display = "none";
    this.refs.bonusLevel.style.display = "flex";
    this.refs.stopBtn.style.display = "block";
    this.refs.instructions.style.display = "block"
    this.refs.headline.style.display = "block"
    this.canvas.style.display = "block"
  }
  sendPoints = () =>{
    this.closeBonus(this.bonusPoints);
    this.refs.bonusLevel.style.display = "none";
  }

  componentDidMount(){
    this.canvas.style.background = "black";
    this.ctx = this.canvas.getContext("2d");
    this.createScene();
    this.startBonusRound(100)
  }
  stopPlayer = () =>{
    this.isPlaying =  false;
    // let hit = false;
    // let bonus = 0;
    this.pointBlobs.forEach( (pb) =>{
      let x = this.centerX + this.pathRadius * Math.cos(pb.angle * Math.PI / 180);
      let y = this.centerY + this.pathRadius * Math.sin(pb.angle * Math.PI / 180);
      let dx = this.playerX - x
      let dy = this.playerY - y
      let distance = Math.sqrt(dx * dx + dy * dy);
      if (distance < this.playerRadius + pb.radius) {
        // hit = true;
        this.bonusPoints = pb.points
      }
    })
    this.endBonus()
  }
  
  endBonus = () =>{
    this.refs.instructions.style.display = "none"
    this.refs.headline.style.display = "none"
    this.canvas.style.display = "none"
    this.refs.bonusPoints.innerHTML = this.bonusPoints
    this.refs.totalPoints.innerHTML = this.currentScore + this.bonusPoints;
    this.refs.stopBtn.style.display = "none";
    this.refs.pointsDisplay.style.display = "block";
    SoundHit.play(this.bonusPoints > 0 ? window.APP_SOUNDS.bonusSuccess : window.APP_SOUNDS.bonusFailure)
  }

  createScene = () =>{
    this.animateTick();
  }
  updateObjects = () =>{
    this.ctx.clearRect(0, 0, 600, 600);
    this.createBgLine();
    this.createPlayer();

    this.pointBlobs.forEach( (pb) =>{
      this.createHitSpot(pb.radius, pb.angle, pb.points)
    });
  }

  createHitSpot = (r, angle, pts) =>{
    let x = this.centerX + this.pathRadius * Math.cos(angle * Math.PI / 180);
    let y = this.centerY + this.pathRadius * Math.sin(angle * Math.PI / 180);
    this.ctx.beginPath();
    this.ctx.arc(x, y, r, 0, 2 * Math.PI, false);
    this.ctx.fillStyle = '#49B1EB';
    this.ctx.fill();

    this.ctx.font = "18px InputMonoCompressed Light";
    this.ctx.textAlign = "center";
    this.ctx.fillStyle = '#000';
    this.ctx.fillText(pts, x, y + 8 );
  }

  createBgLine = () =>{
    this.ctx.beginPath();
    this.ctx.arc(300, 300, 250, 0, 2 * Math.PI, false);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = '#707070';
    this.ctx.stroke();
  }
  createPlayer = () =>{
    this.createBgLine()
    this.ctx.beginPath();
    this.ctx.arc(this.playerX, this.playerY, this.playerRadius, 0, 2 * Math.PI, false);
    this.ctx.fillStyle = '#FFF';
    this.ctx.fill();
  }

  animateTick = () =>{
    // playerX = cx + radius * Math.cos(angle * Math.PI / 180);
    // playerY = cy + radius * Math.sin(angle * Math.PI / 180);
    if(this.isPlaying){
      this.playerX = this.centerX + this.pathRadius * Math.cos(this.playerAngle * Math.PI / 180);
      this.playerY = this.centerY + this.pathRadius * Math.sin(this.playerAngle * Math.PI / 180);
      this.playerAngle -= 3   ;
      this.updateObjects()
    }
    

    requestAnimationFrame( this.animateTick.bind(this) );
  }

  

  render() {
    return (
      <div ref="bonusLevel" id="bonus-level">
        <h2 ref="headline">BONUS STAGE</h2>
        <canvas  ref={ref => (this.canvas = ref)} id="canvas" width="600" height="600"></canvas>
       
        <div id="centerContent">
          <div ref="pointsDisplay" className="points">
            <div className="end-points-label">Bonus: <span ref="bonusPoints">10</span></div>
            <div className="end-points-total-label">TOTAL POINTS: <span  ref="totalPoints" id="end-total-score">100</span></div>
            <button ref="continueBtn" className="button primary" onClick={this.sendPoints}>Continue</button>
          </div>
          <button className="button primary" ref="stopBtn" onMouseDown={this.stopPlayer}>STOP</button>
        </div>
        
        <div id="instructions" ref="instructions">Stop the white orb in a blue orb for extra points.</div>
      </div>
    )
  }
}
export default BonusLevel;