import React, { Component } from 'react';

class Monitor extends Component {
  
  render() {
    return (
      <div className="sub-monitor-small-holder">
        <span>{this.props.label}</span>
        <div className="sub-monitor-small">    
          <div className="sub-monitor-fill" style={{height:100 * this.props.data + "%" }}></div>
        </div>
      </div>
    );
  }
}
export  default Monitor;