import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import blobUi from '../images/how-to/blob-ui.jpg'
import formulaUi from '../images/how-to/formula-ui.png'
import bonusUi from '../images/how-to/bonus-ui.png'

class HowToPage extends Component {
  constructor(props) {
    super(props)
    console.log(props)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="container">
        {this.props.close && (
          <button className="button top-left fixed" onClick={this.props.close}>Go Back</button>  
        )}

        {!this.props.close && (
          <button className="button top-left fixed" onClick={this.props.history.goBack}>Go Back</button>
        )}
        
        <div id="how-to-page">
          <h2>How To Play</h2>
          
          <section>
            <h3>EPBR FORECASTING</h3>
            <img src={blobUi} alt="Blob UI" />
            <p>Orthophosphate (OP) levels representing health of the EBPR process are reflected in the texture of the central blob.</p>
            <p>The past 15 days of relevant data to EBPR health is simulated over 30 seconds. Your task is to determine if over the next 7 days, EBPR will be stable (remain smooth/under threshold value designating stable from unstable operation) or be vulnerable to upsets.</p>
            <p>Remember this is simulated from real data. It takes experience to pick up on patterns, so keep trying and learning.</p>
          </section>

          <section>
            <h3>Equation Identification</h3>
            <img src={formulaUi} alt="Formula UI" />
            <p>Correctly identify the equation that applies to the concept/definition in the center circle.</p>
          </section>

          <section>
            <h3>ORB CAPTURE</h3>
            <img src={bonusUi} alt="Bonus UI" height="400" />
            <p>When the white dot is over the blue orbs, click to capture and receive bonus points.</p>
            <p>You can’t lose lives on this stage.</p>
          </section>
        </div>
      </div>
    )
  }
}

export default withRouter(HowToPage)
