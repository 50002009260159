import React, { Component } from 'react';
import * as THREE from "three";
import { TweenMax, Linear} from "gsap";
import BlobTimer from '../DataDisplays/BlobTimer'
import perlinNoise3d from 'perlin-noise-3d'

class BlobView extends Component {
  constructor(props){
    super(props);
    // this.speed = 30;
    this.speed = props.speed;
    this.sampleChange = props.sampleChange;
    this.currentData = props.currentData
    this.blobNoise = props.currentData.blobNoise;
    this.blobRadius = props.currentData.blobRadius;
    this.blobNoiseHeight = props.currentData.blobNoiseHeight;
    this.backgroundColor = props.currentData.backgroundColor;
    // this.dataChart = React.createRef();
    this.frameCounter = 0;
    this.currentSample = 0;
    this.realTimeData = 0;
    this.blobTimer= React.createRef();
  }
  newData = (bn, br, bh, bg) =>{
    this.frameCounter = 0;
    this.currentSample = 0;
    this.sampleChange(this.currentSample);
    this.realTimeData = 0;
    this.blobNoise = bn;
    this.blobRadius = br;
    this.blobNoiseHeight = bh;
    this.backgroundColor = bg;
    // this.data.push(0)
  }
  shouldComponentUpdate() {
    return false
  }
  componentDidMount(){
    
    this.blobRadiusTxt = document.getElementById("blob-radius");
    this.blobNoiseTxt = document.getElementById("blob-noise");
    this.blobHeightTxt = document.getElementById("blob-height");
    this.blobColorTxt = document.getElementById("blob-color");
    

    this.createScene();
    this.createBlob(0,0,0);
    this.animateTick();
  }

  componentWillUnmount() {
    try {
      cancelAnimationFrame(this.raf)
      this.renderer.dispose()
    } catch(e) {}
  }

  createScene = () =>{
    this.noise = new perlinNoise3d();
    
    this.time = 0;
		this.clock = new THREE.Clock();
    this.currentMeta = 0;

    
    this.scene = new THREE.Scene();
    
    // this.bgColor = new THREE.Color( 0x454ec7 );
    // this.scene.background = this.bgColor 
    // this.camera = new THREE.PerspectiveCamera( 45, (window.innerWidth * 0.96)/ 800, 0.1, 1000 );

    
    
    

    this.renderer = new THREE.WebGLRenderer({antialias:true, alpha: true});
    // this.renderer.setSize( window.innerWidth * 0.96, 800);
    if(window.innerWidth < 768){
      this.camera = new THREE.PerspectiveCamera( 45, (window.innerWidth * 1.25) / (window.innerWidth * 1.25), 0.1, 1000 );
      this.renderer.setSize( window.innerWidth * 0.96, window.innerWidth * 0.96);
    }else{
      this.camera = new THREE.PerspectiveCamera( 45, (window.innerWidth * 1.25) / (window.innerHeight * 1.25), 0.1, 1000 );
      this.renderer.setSize( window.innerWidth * 0.96, window.innerHeight * 0.8);
    }
    this.camera.position.set( 0, 0, 6);
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      
    this.renderer.shadowMap.enabled = true;
    this.renderer.setClearColor( 0x000000, 0 )
    this.renderer.setPixelRatio(window.devicePixelRatio)
    // this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.mount.appendChild( this.renderer.domElement );
    window.addEventListener('resize', this.onWindowResize)
    var ambientLight = new THREE.AmbientLight( 0xffffff );
    this.scene.add( ambientLight );
  }


  createBlob = (x, y, z) =>{
    // var material = new THREE.MeshNormalMaterial();
    var material = new THREE.MeshBasicMaterial({color:0xA0A0A0});
    material.wireframe = true;
    this.sphere = new THREE.Mesh(window.BLOB_SPHERE_GEOMETRY, material);
    this.wireframeMaterial = new THREE.LineBasicMaterial( {color:0x458af8} );
    // wireframeMaterial.depthTest = false;
    this.wireframeMaterial.opacity = 0.75;
    this.wireframeMaterial.transparent = true;
    this.wireframe = new THREE.LineSegments( window.BLOB_EDGES_GEOMETRY, this.wireframeMaterial );
    this.sphere.position.set(x, y, z);
    // this.blobs.push(sphere) 
    this.scene.add(this.sphere);
    this.scene.add(this.wireframe);
  }

  colorUpdate = (c) =>{
    this.bgColor.setHSL(c.h, c.s, c.l)
    // this.gridMaterial.color.setHSL(c.h)
  }
  wireColorUpdate = (c) =>{
    // this.bgColor
    this.wireframeMaterial.color.setHSL(c.h, c.s, c.l)
    
  }

  avgData = (dArr) => {
    let nextStep =  this.currentSample + 1;
    if (nextStep  > dArr.length - 1) {nextStep = 0;} 
      let diff = 0
      // let diff = (this.blobVars[i][this.currentSample] - this.blobVars[i][nextStep]) / 60
      if (dArr[this.currentSample] > dArr[nextStep]){
        diff = dArr[this.currentSample] - (((dArr[this.currentSample] - dArr[nextStep]) /this.speed) * this.frameCounter)
      }else{
        diff = dArr[this.currentSample] + (((dArr[nextStep] - dArr[this.currentSample]) /this.speed  ) * this.frameCounter)
      }
      return diff
  }

  updateBlob = () => {
    var time = performance.now() * 0.001;
    let noiseLevel =  this.avgData(this.blobNoise)
    let blobRadius =  this.avgData(this.blobRadius)
    let blobNoiseHeight =  this.avgData(this.blobNoiseHeight)

    let k = noiseLevel * 5;
    for (var i = 0; i < this.sphere.geometry.vertices.length; i++) {
        var p = this.sphere.geometry.vertices[i];
        p.normalize().multiplyScalar(blobRadius  + blobNoiseHeight  * this.noise.get(p.x * noiseLevel *k  + time, p.y * noiseLevel *k, p.z * noiseLevel * k));
    }
    this.sphere.geometry.verticesNeedUpdate = true;
    this.wireframe.geometry = this.sphere.geometry;
    this.wireframe.geometry.verticesNeedUpdate = true;
  }

  onWindowResize = () => {
    if(window.innerWidth < 768){
      this.camera = new THREE.PerspectiveCamera( 45, (window.innerWidth * 0.96) / (window.innerWidth * 0.96), 0.1, 1000 );
      this.renderer.setSize( window.innerWidth * 0.96, window.innerWidth * 0.96);
    }else{
      this.camera = new THREE.PerspectiveCamera( 45, (window.innerWidth * 0.96) / (window.innerHeight * 0.8), 0.1, 1000 );
      this.renderer.setSize( window.innerWidth * 0.96, window.innerHeight * 0.8);
    }
    this.camera.position.set( 0, 0, 6);
    this.camera.updateProjectionMatrix();
  }

  animateTick = () =>{
    if (this.frameCounter === 0){
      this.currentSample++;
      
      if (this.currentSample > this.blobNoise.length - 1) {this.currentSample = 0;}
      this.sampleChange(this.currentSample);
      this.blobTimer.current.timerFill(this.currentSample)
      
      this.blobRadiusTxt.innerHTML = this.currentData.nsData.x[this.currentSample][0].toFixed(3)
      this.blobNoiseTxt.innerHTML = this.currentData.nsData.x[this.currentSample][1].toFixed(3)
      this.blobHeightTxt.innerHTML = this.currentData.nsData.x[this.currentSample][2].toFixed(3)
      this.blobColorTxt.innerHTML = this.currentData.nsData.x[this.currentSample][3].toFixed(3)
      var wireColor  = this.wireframeMaterial.color.getHSL({h: 0, s: 0, l: 0})
      TweenMax.to(wireColor , 1, {s: this.backgroundColor[this.currentSample], l: this.backgroundColor[this.currentSample],  onUpdate:this.wireColorUpdate, onUpdateParams:[wireColor], ease:Linear.easeNone});
    }
    
    this.frameCounter++;
    this.updateBlob()
    if(this.frameCounter > this.speed){
      // this.updateStacks()
      this.frameCounter = 0
    }

    // this.meta.rotation.x += 0.01;
    this.renderer.render( this.scene, this.camera );
    // this.controls.update();
    this.raf = requestAnimationFrame( this.animateTick.bind(this) );
  }

  render() {
    return (
      <div id="blob-holder">
        <BlobTimer ref={this.blobTimer} />
        {/* <div id="time-holder">
          <svg width="600" height="600">
            <circle cx={300} cy={300} r={275} fill="none" strokeWidth="2" stroke="white"/>
            <circle id="time-circle" cx={300} cy={300} r={278} fill="none" strokeWidth="8" stroke="white"/>
          </svg>
        </div> */}

        <div id="blob-stats">
          <div><span className="blob-label">RADIUS</span><span>ANAEROBIC OP AVG</span><span id="blob-radius">0.1</span></div>
          <div><span className="blob-label">NOISE</span><span>AEROBIC OP AVG</span><span id="blob-noise"></span></div>
          <div><span className="blob-label">HEIGHT</span><span>AEROBIC OP MAX</span><span id="blob-height"></span></div>
          <div><span className="blob-label">COLOR</span><span>AB1 FLOW AVG</span><span id="blob-color"></span></div>
        </div>
        <div id="background-animation" ref={ref => (this.mount = ref)} />
      </div>
    );
  }
}

export  default BlobView;