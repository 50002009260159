import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class AboutPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="container">
        {this.props.close && (
          <button className="button top-left fixed" onClick={this.props.close}>Go Back</button>  
        )}

        {!this.props.close && (
          <button className="button top-left fixed" onClick={this.props.history.goBack}>Go Back</button>
        )}

        <div id="about-page">
          <h2>About</h2>
          <p>New wastewater treatment operators are entering into an increasingly demanding environment – and may not have much time to learn through trial and experience on the job.</p>
          <p>Maia Analytica’s wastewater operator training games are custom designed using data from your facility to simulate key operational decision points and ensure all staff has the requisite knowledge base to be successful and achieve ideal treatment outcomes from the unique systems they will be operating.</p>
          <p>We work with facilities to design engaging and motivating environments where optimal learning can occur. Through gameplay operators can earn valuable CEUs while plant managers can track the educational progress of their staff in key target areas.</p>
          <p>Curious to see how this could work for your facility? Send an email to <a href="mailto:keaton@maiaanalytica.com">keaton@maiaanalytica.com</a></p>
        </div>
      </div>
    )
  }
}

export default withRouter(AboutPage)
