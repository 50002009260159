import React from 'react'
import mutedImg from '../images/icon-volume-muted.svg'
import unmutedImg from '../images/icon-volume.svg'

class MuteButton extends React.Component {
  state = {muted: false}

  componentDidMount() {
    this.setState({muted: window.Howler._muted})
  }

  toggleMute() {
    if (window.Howler._muted) {
      window.Howler.mute(false)
      this.setState({muted: false})
    } else {
      window.Howler.mute(true)
      this.setState({muted: true})
    }
  }
  
  render() {
    return (
      <button className={`button plain mute ${this.props.className}`} onClick={this.toggleMute.bind(this)}>
        {this.state.muted && (
          <img src={mutedImg} alt="Muted" />
        )}

        {!this.state.muted && (
          <img src={unmutedImg} alt="Playing" />
        )}
      </button>
    )
  }
}

export default MuteButton