import React, { Component } from 'react';
import Chart from 'chart.js';
import { ChartThreshold, ChartVerticalLine, ChartText } from './Chart.Plugins'

class YChart extends Component {
  constructor(props){
    super(props)

    const nsDataArray = []
    for (const nsArray of props.currentData.nsData.x) {
      nsDataArray.push(nsArray[1])
    }
    this.chartData = nsDataArray.concat(props.currentData.nsData.y)

    this.startDate = new Date(props.currentData.timestampData.x[0] * 1000)
    this.endDate = new Date(props.currentData.timestampData.y[props.currentData.timestampData.y.length - 1] * 1000)
  }

  componentDidMount() {
    this.createChart()
  }

  createChart = () => {
    var ctx = document.getElementById('chart');
    ctx.style.backgroundColor = '#000000';
    this.lineChart = new Chart(ctx, {
        type: 'line',
        borderColor: "#ffffff",
        plugins: [ChartThreshold, ChartVerticalLine, ChartText],
        startDate: this.startDate,
        endDate: this.endDate,
        data: {
          labels: this.chartData,
          datasets:[
          {
            label: "data",
            data: this.chartData,
            borderColor: "#FFFFFF",
            fill: false,
            borderWidth: 2,
            backgroundColor: '#ffffff',
          }]
        },
        options: {
          animation: {
            duration: 0
          },
          layout: {
            padding: {
              left: 27,
              bottom: 27,
            }
          },
          scales: {
            yAxes: [{
              display: false,
              beginAtZero: true,
              borderColor: "green",
              ticks: {
                display:true,
                fontColor: "white",
                fontSize: 8,
                max: 7.125,
                min: 0,
                borderColor: "#ffffff",
              },
              gridLines : {
                display : false,
                color: "#ffffff"
              }
            }],
             xAxes: [{
              display: false,
              color: "#ffffff",
              // beginAtZero: true,
              ticks: {
                 display: true,
                 color: "#ffffff",
                 fontColor: "white",
                  fontSize: 8,
                  // min: 60,
                  // max: 60,
                  borderColor: "#ffffff",
              },
              gridLines : {
                display : true,
                color: "#ffffff",
              }
            }]
          },
          legend: {
            display: false,
            labels: {
              fontColor: "white",
              // fontSize: 18
            }
          },
          elements: {
            point:{
              radius: 0
            }
        }
        }
    });
  }

  render() {
    return (
      <div id="sample_holder">
        <canvas id="chart" width="427" height="300"></canvas>
      </div>
    );
  }

}
export default YChart;