import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom'
import YChart from '../Utils/YChart.js'
import MultiEnd from '../Views/MultipleChoiceEnd'
import BlobDebug from '../DataDisplays/BlobDebug'
import SoundHit from '../Utils/SoundHit'

import correctImg from '../images/correct.png'
import incorrectImg from '../images/incorrect.png'

class EndLevel extends Component {
  state = {
    gameOver: false,
    win: false,
    type: "",
  }
  constructor(props){
    super(props);
    // console.log(this.props.coreInfo.lives)
    this.livesElems = []
    if(props.win){
      this.message = "Correct"
      this.resultImg = correctImg
    }else{
      this.message = "Incorrect"
      this.resultImg = incorrectImg
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    SoundHit.play(this.props.win ? window.APP_SOUNDS.hitSuccess : window.APP_SOUNDS.hitFailure)
  }

  endLifeDots = () =>{
    this.livesElems=[]
    for (let i = 0; i < this.props.lives.maxLives; i++) {
      let lifeDot
      if(i >= this.props.lives.currentLives){
        lifeDot =  React.createElement('div', {className:'dead', key: i+"_life_end"} );
      }else{
        lifeDot =  React.createElement('div', {key: i+"_life_end"});
      }
      this.livesElems.push(lifeDot)
    }
    if(this.props.lives.currentLives === 0){
      this.buttonMessage = "Continue"
    }else{
      this.buttonMessage = "Next Stage"
    }
  }
  render() {
    this.endLifeDots()
    let typeInfo = []
    if(this.props.type === "blob"){
      typeInfo.push(<div id="blob-guess" key="blob_guess">{this.props.guess}</div>)
      typeInfo.push(<YChart currentData={this.props.currentData} key="ychart"/>)
      if (this.props.debugMode) {
        typeInfo.push(<BlobDebug currentData={this.props.currentData} key="BlobDebug" />)
      }
    }else{
      typeInfo = (
        <MultiEnd
          win={this.props.win}
          question={this.props.question}
          answerIndex={this.props.answerIndex}
        />
      )      
    }
    return (
      <div className="end-level">
        <div className="end-wrapper">
          <div className="end-status">
            <img  src={this.resultImg} alt="result"/>
            <h2 className={this.props.win ? `correct` : ``}> {this.message } </h2>
            {typeInfo}
          </div>
          <div id="end-points">
            <div className="end-points-label">POINTS EARNED: <span>{this.props.points.newPoints}</span></div>
            <div className="end-points-total-label">TOTAL POINTS: <span >{this.props.points.totalPoints}</span></div>

            <div id="end-lives">
              LIVES LEFT:
              <div id="lives">
                {this.livesElems}
              </div>
            </div>
          </div>
          <button className="end-btn button primary" ref="levelBtn"  id="level-btn" onClick={this.props.newLevel}>{this.buttonMessage}</button>
        </div>
      </div>
    )
  }
}

export default EndLevel;