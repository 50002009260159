import React, { Component } from 'react'
import { shuffle } from '../Utils/Helpers'
import FormulaImage from '../Components/FormulaImage'
import QuestionsData from '../Data/formulas.json'

class MultipleChoice extends Component {
  constructor(props){
    super(props);
    this.endLevel = props.endMultiLevel
    this.formulas = QuestionsData.formulas

    this.state = { answerIndexes: shuffle([0, 1, 2]), question: this.formulas[Math.floor(Math.random() * this.formulas.length)], selectedAnswerIndex: null }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {}

  selectAnswer(e, answerIndex) {
    this.setState({ selectedAnswerIndex: answerIndex })
  }

  submitAnswer() {
    this.endLevel(this.state.selectedAnswerIndex, this.state.question)
  }

  render() {
    return (
      <div id="multiple-choice">

        <div id="question-holder">
          <div
            id="question"
            dangerouslySetInnerHTML={{__html: this.state.question.title}}
          ></div>
          {this.state.question.subtitle && (
            <div id="sub-question" dangerouslySetInnerHTML={{__html: this.state.question.subtitle}}></div>
          )}
        </div>

        <div id="answers-holder">
          <span>Choose The Correct Formula</span>
          {this.state.answerIndexes.map((i) => {
            return (
              <div
                className={`answer ${this.state.selectedAnswerIndex === i ? "selected" : ""}
                  ${(this.props.debugMode && i === 0) ? "correct" : ""}`}
                key={`answer-${i}`}
                onClick={(e) => this.selectAnswer(e, i)}
              >
                <FormulaImage path={`images/formulas/${this.state.question.id}/${i}`} />
              </div>
            )
          })}
          <button className="button primary" disabled={this.state.selectedAnswerIndex === null} onClick={this.submitAnswer.bind(this)}>Submit Answer</button>
        </div>
      </div>
    );
  }
}

export default MultipleChoice;