import React, { Component } from 'react';

class Monitor extends Component {
  constructor(props){
    super(props);
    this.speed = props.speed;
    this.label = props.label;
    this.data = props.data;
    this.frameCounter = 0;
    this.currentSample = 0;
    this.lastData = 0.5;
    this.samples = new Array(20)
    this.samples.fill(0.5)
  }
  shouldComponentUpdate() {
    return false
  }

  componentDidMount(){
    this.createScene();
    this.animateTick();
  }

  createScene = () =>{
    this.canvas.style.background = "none";
    this.ctx = this.canvas.getContext("2d");
    this.ctx.lineWidth = "2";
    this.ctx.strokeStyle = "#FFFFFF";
    this.totalWidth = 434 - 20;
    this.withSeg = this.totalWidth / this.speed 

    this.ctx.beginPath();
    this.ctx.moveTo(0, 80);
    this.ctx.lineTo(10, 80);

    this.ctx.lineTo(424, 80);
    this.ctx.lineTo(434, 80);
    this.ctx.stroke();
    // setSize( 433, 190 );
    // this.mount.appendChild( this.renderer.domElement );
  }
  avgData = (dArr) => {
    let nextStep =  this.currentSample + 1;
    if (nextStep  > dArr.length - 1) {nextStep = 0;} 
      let diff = 0
      // let diff = (this.blobVars[i][this.currentSample] - this.blobVars[i][nextStep]) / 60
      if (dArr[this.currentSample] > dArr[nextStep]){
        diff = dArr[this.currentSample] - (((dArr[this.currentSample] - dArr[nextStep]) /this.speed) * this.frameCounter)
      }else{
        diff = dArr[this.currentSample] + (((dArr[nextStep] - dArr[this.currentSample]) /this.speed  ) * this.frameCounter)
      }
      return diff
  }
  updateLine = () =>{
    this.ctx.clearRect(0, 0, 434, 160);
    this.ctx.beginPath();
    this.ctx.moveTo(0, 0);
    this.ctx.lineTo(0, 80);

 
    this.ctx.lineTo(20, this.samples[0] * 160);
    this.ctx.lineTo(40, this.samples[1] * 160);
    this.ctx.lineTo(60, this.samples[2] * 160);
    this.ctx.lineTo(80, this.samples[3] * 160);

    this.ctx.lineTo(100, this.samples[4] * 160);
    this.ctx.lineTo(120, this.samples[5] * 160);
    this.ctx.lineTo(140, this.samples[6] * 160);
    this.ctx.lineTo(160, this.samples[7] * 160);
    this.ctx.lineTo(180, this.samples[8] * 160);
    this.ctx.lineTo(200, this.samples[9] * 160);

    this.ctx.lineTo(220, this.samples[10] * 160);
    this.ctx.lineTo(240, this.samples[11] * 160);
    this.ctx.lineTo(260, this.samples[12] * 160);
    this.ctx.lineTo(280, this.samples[13] * 160);
    this.ctx.lineTo(300, this.samples[14] * 160);
    this.ctx.lineTo(320, this.samples[15] * 160);
    this.ctx.lineTo(340, this.samples[16] * 160);
    this.ctx.lineTo(360, this.samples[17] * 160);
    this.ctx.lineTo(380, this.samples[18] * 160);
    this.ctx.lineTo(400, this.samples[19] * 160);
    this.ctx.lineTo(420, this.samples[20] * 160);

    this.ctx.lineTo(424, 80);
    this.ctx.lineTo(434, 80);
    this.ctx.stroke();

  }

  animateTick = () =>{
    
    if (this.frameCounter === 0){
      this.lastData = this.data[this.currentSample]
      this.currentSample++;
      if (this.currentSample > this.data.length - 1) {this.currentSample = 0;}
      this.samples.shift()
      this.samples.push(this.data[this.currentSample]);
      // console.log(this.samples)
    }
    this.updateLine()
    this.frameCounter++;
    if(this.frameCounter > this.speed){
      // this.updateStacks()
      this.frameCounter = 0
    }

    requestAnimationFrame( this.animateTick.bind(this) );
  }

  render() {
    return (
      <div className="monitor-holder">
        <div className="monitor-lable">{this.label}</div>
        <canvas  ref={ref => (this.canvas = ref)} id="canvas" width="434" height="160"></canvas>
        {/* <div id="monitor-animation-4" ref={ref => (this.mount = ref)} /> */}
      </div>
    );
  }
}
export  default Monitor;