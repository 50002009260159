import React, { Component } from 'react';
class BlobTimer extends Component {
  radius = 300
  currentSample = 0
  // constructor(props){
  //   super(props)
  // }
  componentDidMount() {
    this.timeSvg = document.getElementById("time-svg")
    this.timeCircle = document.getElementById("time-circle")
    this.timeBorder = document.getElementById("time-border")
    window.addEventListener('resize', this.onWindowResize)
    this.sizeTimer()

  }
  onWindowResize = () =>{
    this.sizeTimer()
  }
  timerFill = (currentSample) =>{
    this.currentSample = currentSample
    let max = Math.round(2 * Math.PI * (this.radius -  25));
    let tickSize =  (this.currentSample/59) * max
    this.timeCircle.style.strokeDasharray =  max
    this.timeCircle.style.strokeDashoffset = max - tickSize
  }
  
  sizeTimer = () =>{
    if(window.innerWidth < 768){
      this.radius = (window.innerWidth * 0.4)
    }else{
      this.radius = (window.innerHeight * 0.3)
    }
    
    // console.log(radius)
    this.timeSvg.setAttribute('width', this.radius * 2 )
    this.timeSvg.setAttribute('height', this.radius * 2 )
    // console.log(radius)
    // this.timeCircle = document.getElementById("time-circle")
    this.timeCircle.setAttribute('cx', this.radius)
    this.timeCircle.setAttribute('cy', this.radius)
    this.timeCircle.setAttribute('r', this.radius -  25);

    this.timeBorder.setAttribute('cx', this.radius)
    this.timeBorder.setAttribute('cy', this.radius)
    this.timeBorder.setAttribute('r', this.radius -  28);
    this.timerFill(this.currentSample)
  }

  render() {
    return (
      <div id="time-holder">
        <svg id="time-svg" width="600" height="600">
          <circle id="time-border" cx={300} cy={300} r={275} fill="none" strokeWidth="2" stroke="white"/>
          <circle id="time-circle" cx={300} cy={300} r={278} fill="none" strokeWidth="8" stroke="white"/>
        </svg>
      </div>
    )
  }
}
export  default BlobTimer;