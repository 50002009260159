import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SoundHit from '../Utils/SoundHit'

class GameOver extends Component {
  constructor(props){
    super(props);
    this.blobStats = this.props.blobData
    this.blobWinCount = this.blobStats.stages.filter(Boolean).length
    this.formulaStats = this.props.multiData
    this.forumlaWinCount = this.formulaStats.stages.filter(Boolean).length
    this.bonusStats = this.props.bonusData
    this.totalPoints = this.props.totalPoints
    this.ceuContactHours = (((this.forumlaWinCount + this.blobWinCount) * 3) / 60).toFixed(2)
  }

  componentDidMount() {
    SoundHit.play(window.APP_SOUNDS.gameOver)
  }

  render() {
    return (
      <div id="game-over">
        <header>
          <h1>Game Over</h1>
        </header>

        <section className="totals">
          <div className="stat">
            <h3>Total Points</h3>
            <h2 className="large">{this.totalPoints}</h2>
          </div>

          <div className="stat">
            <h3>CEU Contact Hours</h3>
            <h2 className="large">{this.ceuContactHours}</h2>
          </div>
        </section>

        <section className="stats">
          <div className="stat blob">
            <h3>EPBR Forecasting</h3>
            <div className="stages">
              {this.blobStats.stages.map((result, i) => {
                return (
                  <div key={i+"_blob"} className={`circle status-${result.toString()}`}></div>
                )
              })}
            </div>
            <p className="points">
              <span>Points</span> {this.blobStats.points}
            </p>
          </div>

          <div className="stat formula">
            <h3>Equation Identification</h3>
            <div className="stages">
              {this.formulaStats.stages.map((result, i) => {
                return (
                  <div key={i+"_multi"} className={`circle status-${result.toString()}`}></div>
                )
              })}
            </div>
            <p className="points">
              <span>Points</span> {this.formulaStats.points}
            </p>
          </div>

          {this.bonusStats.stages.length > 0 && (
            <div className="stat bonus">
              <h3>Orb Capture</h3>
              <div className="stages">
                {this.bonusStats.stages.map((result, i) => {
                  return (
                    <div key={i+"_bonus"} className={`circle bonus`}>{result}</div>
                  )
                })}
              </div>
              <p className="points">
                <span>Points</span> {this.bonusStats.points}
              </p>
            </div>
          )}
        </section>

        <section>
          <Link to="/"><button className="button">Home</button></Link>
        </section>
      </div>
    )
  }
}
export default GameOver;
