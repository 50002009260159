import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import MuteButton from '../Utils/MuteButton'

import logoImg from '../images/maia-logo.svg'
import wilmaLogoImg from '../images/wilma-logo.svg'
import infinityLogoImg from '../images/icon-infinity-white.svg'

class HomePage extends Component {
  constructor(props){
    super(props)

    this.debugMode = (props.location.search.includes("debug") ? true : false)
  }

  render() {
    return (
      <div id="home-page">
        <div id="home-top">
          <div id="address">
            <span className="alt">Location:</span>
            <span>CLEAN WATER SERVICES</span>
            <span>Durham Treatment Facility</span>
            <span>Tigard, OR, USA</span>
          </div>
        </div>

        <div id="home-center">
          <div id="home-menu">
            <img src={wilmaLogoImg} alt="WILMA Wastewater Integrated Learning Management Activity" height="72" width="343" />

            <div id="home-btns">
              <Link to={{
                pathname: "/game",
                state: { debugMode: this.debugMode }
              }}><button className="primary button">Start</button></Link>
              <Link to="/how-to-play"><button className="button">How to play</button></Link>
              <Link to="/about"><button className="button">About</button></Link>
            </div>
          </div>

          <Link to={{
            pathname: "/game",
            state: { lives: -1, maxLives: -1, levelOrder: ["blob"], debugMode: true }
          }}>
            <button className="button plain bottom-left">
              <img src={infinityLogoImg} height="20" alt="" />
            </button>
          </Link>
        </div>

        <a href="https://maiaanalytica.com" target="_blank" rel="noopener noreferrer" className="button plain bottom-right">
          <img src={logoImg} height="30" alt="MAIA" />
        </a>

        {this.debugMode && (
          <button className="button plain alt bottom-center">Debug Mode</button>
        )}

        <MuteButton className="top-right" />
      </div>
    )
  }
}
export default withRouter(HomePage)
